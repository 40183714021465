<template>
    <v-autocomplete 
        clearable
        dense
        solo
        v-model="model" 
        :items="getItems()"
    ></v-autocomplete>
</template>

<script>
    import { get } from "vuex-pathify";
    export default {
        name : "AutocompleteProductCode",
        computed: {
            items : get("items/items")
        },
        data: () => ({
            model: null,
        }),
        methods : {
            getItems : function () {
                return !!this.items && !!this.items.length? 
                    this.items.map(el => ({ text : `${el.code} - ${el.description}`, value : el.code }))
                    : [];
            },
        },
    }
</script>