<template>
  <v-card class="mx-2 my-2 px-2 py-2" style=" width:96%;">
    <v-row justify="center">
      <v-col cols="12" sm="1">
        <v-btn block outlined color="primary" class="mx-2 my-2" @click="$emit('closeCart')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="10">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate color="white">
              {{ $t('filters') }}
              <template v-slot:actions>
                <v-icon color="error" v-if="!!isFiltered()">mdi-alert-circle</v-icon>
                <v-icon color="primary" v-else>mdi-filter-plus-outline</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" sm="10">
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-chip class="ma-1" color="primary" label text-color="white" v-for="(el,i) in valueBreadcrumbs"
                              :key="i" @click="setFilter(el.branch, null)">
                        <v-icon left>mdi-close</v-icon>
                        {{ el.value }}
                      </v-chip>
                      <v-chip class="ma-1" color="primary" label text-color="white" v-for="(el,i) in filter.text"
                              :key="i" @click="filter.text.splice(i, 1)">
                        <v-icon left>mdi-close</v-icon>
                        {{ el }}
                      </v-chip>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-tabs show-arrows color="primary accent-4"
                              v-if="!filter.group && !filter.collection && !filter.family">
                        <v-tabs-slider color="primary"></v-tabs-slider>
                        <v-tab v-for="family in getFamilies()" :key="'family'+family.value"
                               @click="setFilter('family', family.value)">{{ family.text }}
                        </v-tab>
                      </v-tabs>
                      <v-tabs show-arrows v-if="!!filter.family && !filter.collection && !filter.group">
                        <v-tabs-slider color="primary"></v-tabs-slider>
                        <v-tab v-for="gr in getGroups()" :key="'group'+gr.value" @click="setFilter('group', gr.value)">
                          {{ gr.text }}
                        </v-tab>
                      </v-tabs>
                      <v-tabs show-arrows v-if="!!filter.group && !filter.collection">
                        <v-tabs-slider color="primary"></v-tabs-slider>
                        <v-tab v-for="coll in getCollections()" :key="'coll'+coll.value"
                               @click="setFilter('collection', coll.value)">{{ coll.text }}
                        </v-tab>
                      </v-tabs>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-combobox :label="$t('filter')" v-model="filter.text" dense clearable
                                  multiple outlined persistent-hint small-chips></v-combobox>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" sm="1">
        <v-btn block outlined color="primary" class="mx-2 my-2" @click="cartDialog = !cartDialog">
          <v-icon>mdi-cart</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="cartDialog" width="600">
      <v-card>
        <v-card-title class="headline grey lighten-2" v-t="'items'"></v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left" v-t="'code'"></th>
                <th class="text-left" v-t="'qty'"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="el in cart" :key="el.code">
                <td>{{ el.code }}</td>
                <td>{{ el.quantity }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="exportCart" v-t="'saveOrderRows'"></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-container fluid style="overflow-y:scroll;">
        <v-row align="center" class="px-2 py-2">
          <v-col cols="12" :sm="getItemsXRow()" v-for="card in searchItem()" :key="card.code">
            <v-card>
              <v-img :src="getItemImage(card)" gradient="to bottom, rgba(0,0,0,.025), rgba(0,0,0,.05)" height="15vh" contain></v-img>
              <v-card-title v-text="card.code"></v-card-title>
              <v-card-subtitle v-text="card.description"></v-card-subtitle>
              <v-card-actions>
                <v-btn elevation="2" color="error" dark fab icon x-small @click="removeItem(card)">
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-text-field outlined dense class="px-10" v-model="card.quantity" label="QTY" hide-details="auto" type="number" min="0" @change="changedItem(card)"></v-text-field>
                <v-spacer></v-spacer>
                <v-btn elevation="2" color="success" dark fab icon x-small @click="addItem(card)">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <v-row align="center" v-if="searchItem().length >= slot">
          <v-col cols="12" sm="4" offset-sm="4">
            <v-btn block color="primary" @click="loadMore">
              {{ $t('loadMore') }}
              <v-icon class="px-3">mdi-cloud-download</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-card>

</template>

<script>
import {call, sync} from 'vuex-pathify';

export default {
  name: 'Catalogue',
  props: ['familyId', 'collection'],
  data: () => ({
    // filterDialog: false,
    cartDialog: false,
    drawer: false,
    group: null,
    filter: {
      text: "",
      family: "",
      group: "",
      collection: "",
      itemsXRow: 2,
    },
    cart: [],
    valueBreadcrumbs: [],
    slot: 24,
    tempQuantity: {}, // ...this.cards.map(el => el.code)
  }),
  methods: {
    isFiltered: function () {
      return !!this.filter.text.length || !!this.filter.family || !!this.filter.group || !!this.filter.collection
    },
    clearFilters: function () {
      this.filter = {text: [], family: "", group: "", collection: "", itemsXRow: 2,};
    },
    getFamilies: function () {
      return this.families.map(el => ({text: el.description, value: el.id}));
    },
    getGroups: function () {
      return this.groups.filter(el => el.familyId === this.filter.family).map(el => ({
        text: el.description,
        value: el.id
      }));
    },
    getCollections: function () {
      return this.collections.filter(el => el.itemGroupId === this.filter.group).map(el => ({
        text: el.description,
        value: el.id
      }));
    },
    getItemsXRow: function () {
      return this.filter.itemsXRow;
    },
    setFilter: function (key, value) {
      this.filter[key] = value;
      this.slot = 20;
      if (!value) this.clearFilter(key);
      this.setBreadCrumb();
      // else this.selectDefault(key);
    },
    exportCart: function () {
      this.$emit("exportedCart", this.cart);
      this.cartDialog = !this.cartDialog;
    },
    clearFilter: function (key) {
      if (key == 'group') this.filter.collection = null;
      if (key == 'family') this.filter.group = this.filter.collection = null;
    },
    setBreadCrumb: function () {
      this.valueBreadcrumbs.length = 0;
      if (!!this.filter.family) this.valueBreadcrumbs.push({
        branch: "family",
        value: this.getFamilies().find(el => el.value === this.filter.family).text
      });
      if (!!this.filter.group) this.valueBreadcrumbs.push({
        branch: "group",
        value: this.getGroups().find(el => el.value === this.filter.group).text
      });
      if (!!this.filter.collection) this.valueBreadcrumbs.push({
        branch: "collection",
        value: this.getCollections().find(el => el.value === this.filter.collection).text
      });
    },
    searchItem: function () {
      let collection = this.items.filter(el =>
          (!this.filter.family || el.itemFamilyId === this.filter.family) &&
          (!this.filter.group || el.itemGroupId === this.filter.group) &&
          (!this.filter.collection || el.itemCollectionId === this.filter.collection) &&
          (!this.filter.text.length || (
              this.filter.text.some(x => (el.code || "").toLowerCase().includes(x.toLowerCase())) ||
              this.filter.text.some(x => (el.description || "").toLowerCase().includes(x.toLowerCase())) ||
              this.filter.text.some(x => (el.barcode || "").toLowerCase().includes(x.toLowerCase()))
          )) &&
          !!el.active && !el.obsolete);
      return collection.slice(0, this.slot).map(x => {
        x.quantity = (this.cart.find(el => el.code === x.code) || {}).quantity || 0;
        return x;
      });
    },

    loadMore: function () {
      this.slot += 12;
    },
    removeItem: function (item) {
      if (+item.quantity >= 1) {
        item.quantity = +item.quantity - 1;
        this.changedItem(item);
      }
    },
    addItem: function (item) {
      item.quantity = +item.quantity + 1;
      this.changedItem(item);
    },
    changedItem: function (item) {
      let itemIndex = this.cart.indexOf(item);
      if (itemIndex >= 0) {
        if (item.quantity > 0) {
          this.cart.splice(itemIndex, 1, item);
        } else {
          this.cart.splice(itemIndex, 1);
        }
      } else {
        this.cart.push(item);
      }
    },
    getItemImage: function (item) {
      let result = 'https://cdn.vuetifyjs.com/images/cards/house.jpg';
      if (!!item && !!item.itemImages && item.itemImages.length > 0) {
        result = (item.itemImages.find(x => x.type = 'pic') || {}).endpoint || result;
      }
      return result;
    },

    initialize() {
      Promise.all([
        this.initItemCollection(),
        this.initItemKitCollection(),
        this.initFamilyCollection(),
        this.initGroupCollection(),
        this.initCollectionCollection()
      ])
      .then(() => {
        this.collection.forEach(el => {
          let item = this.items.find(x => x.code == el.itemCode);
          if (!!item) this.cart.push(Object.assign(item, el));
        });
      });
    },

    ...call("items/*"),
    ...call("itemKits/*"),
    ...call("families/*"),
    ...call("groups/*"),
    ...call("collections/*"),
  },
  watch: {
    group() {
      this.drawer = false
    },
  },
  created() {
    this.initialize();
  },
  computed: {
    items: sync("items/items"),
    kits: sync("itemKits/itemKits"),
    families: sync("families/families"),
    groups: sync("groups/groups"),
    collections: sync("collections/collections"),
  },
  components: {}
}
</script>

<style>
.badgeCatalog {
  margin: 10px;
}
</style>