<template>

    <v-card class="mx-auto my-5 px-7" color="#3E5565" dark max-width="350">
        <v-card-title>
            <v-icon large left>
                mdi-account
            </v-icon>
            <v-text-field v-text="contact.name" label="name"></v-text-field>
        </v-card-title>
        <v-text-field v-text="contact.tel" label="tel"></v-text-field>
        <v-text-field v-text="contact.mail" label="e-mail"></v-text-field>
        <v-text-field v-text="contact.role"  label="role"></v-text-field>
            <v-card-actions>
            <v-list-item class="grow">
                <v-row align="center" justify="end">
                    <v-btn outlined color="white" v-t="'add'"></v-btn>
                </v-row>
            </v-list-item>
        </v-card-actions>
    </v-card>
    
</template>

<script>
    export default {
        name: 'CardContact',

        props: {  
            contact: {
                type: Object,
                default: () => [],
            }, 
        }
    }
</script>