<template>
  <td>
    <button v-show="!!kitCollapsing && !!dataItem.kitCode && dataItem.kitCode === dataItem.itemCode" class="k-secondary k-button k-grid-info-command" @click="explodeKit" v-t="'kit'"></button>
    <button v-if="!confirmAction" class="k-primary k-button k-grid-remove-command" @click="handleAction" v-t="'remove'"></button>
    <button v-if="confirmAction" class="k-danger k-button k-grid-remove-command" @click="removeHandler">
      <v-icon large left>mdi-alert-circle-outline</v-icon>{{ $t('confirm') }}
    </button>
  </td>
</template>

<script>
export default {
  name: "CommandCell",
  props: {
    dataItem: Object,
    kitCollapsing: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    confirmAction: false
  }),
  methods: {
    removeHandler: function () {
      this.$emit('remove', {dataItem: this.dataItem});
      this.confirmAction = false;
    },
    explodeKit: function () {
      this.$emit('showKit', {dataItem: this.dataItem});
    },
    handleAction: function() {
      this.confirmAction = true;
      setTimeout(() => {
        if(!!this.confirmAction) {
          this.confirmAction = false;
        }
      }, 3000)
    }
  }
}
</script>